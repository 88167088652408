<template>
  <div class="page-other-transaction">
    <bg-grid>
      <bg-grid-item :col="12">
        <bg-text size="heading-3">Data Transaksi Lainnya</bg-text>
      </bg-grid-item>

      <bg-grid-item :col="3" class="mb-12">
        <bg-select
          v-model="selectedContent"
          data-testid="content-switcher"
          :options="contents"
        />
      </bg-grid-item>

      <bg-grid-item :col="12">
        <router-view />
      </bg-grid-item>
    </bg-grid>
  </div>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgText,
  BgSelect,
} from 'bangul-vue';

export default {
  name: 'OtherTransaction',

  components: {
    BgGrid,
    BgGridItem,
    BgText,
    BgSelect,
  },

  created() {
    this.selectedContent = this.currentPage;
  },

  data() {
    return {
      selectedContent: '',

      contents: [
        { val: 'expenditure', label: 'Pengeluaran Properti'},
        { val: 'deduction', label: 'Denda & Penalti', disabled: true},
      ],
    };
  },

  computed: {
    currentPage() {
      return this.$route.name.split('.')[1];
    }
  },

  watch: {
    selectedContent(val) {
      if (this.currentPage !== val) {
        this.$router.push({ name: `other-transaction.${val}` })
      }
    }
  },
};
</script>

<style lang="scss" src="./OtherTransaction.scss" scoped />
